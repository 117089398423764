/* .candidateTable {
    max-height: 20vh; /* Adjust the height as needed 
    overflow-y: auto;
    overflow-x: auto;
    max-width: 50vw;
}

.candidateTable table {
    width: 100%;
    border-collapse: collapse;
}
.candidateTable th, .candidateTable td {
    border: 1px solid #958b8b;
    padding: 8px;
}

.candidateTable th {
    background-color: #f2f2f2;
    text-align: left;
}

.candidateTable td{
    background-color: white;
} */

.candidateTableContainer {
    display: flex;
}

.candidateTableWrapper {
    max-height: 300px; /* Adjust the height as needed */
    overflow-y: auto;
    width: 100%;
    border: 1px solid #ccc;
}

.candidateTable {
    width: 100%;
    border-collapse: collapse;
}

.candidateTable th {
    position: sticky;
    top: 0;
    background-color: #f4f4f4;
    text-align: left;
}

.candidateTable th, .candidateTable td {
    padding: 8px;
    border: 1px solid #ccc;
    text-align: left;
}

/* Alternate row colors */
.candidateTable tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.candidateTable tbody tr:nth-child(even) {
    background-color: #e0e0e0;
}

/* Highlight selected row */
.candidateTable tbody tr.selected {
    background-color: #cce5ff;
    font-weight: bold;
}

.candidateDetails {
    margin-left: 20px;
    /*width: 40%;*/
}
