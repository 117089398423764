.Admin_container {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu {
    height: 95%;
    width: 10%;
    border-color: black;
    border-width: 0.2vh;
    border-style: solid;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu_option{
    background-color: rgba(255, 255, 255, 1);
    border-color: transparent;
    cursor: pointer;
    width: 100%;
    font-size: large;
    margin-bottom: 3vh;
}

.menu_option_selected{
    background-color: #b2d3b4;
    cursor: pointer;
    border-color: transparent;
    width: 100%;
    font-size: large;
    margin-bottom: 3vh;
}

.content_renderer {
    display: flex;
    height: 95%;
    width: 85%;
    border-color: black;
    border-style: solid;
    border-width: 0.2vh;
    margin-right: 2%;
    background-color: #b2d3b4;
    border-radius: 25px;

    flex-direction: column;
}

.info_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Admin_Form
{
    height: 100%;
    width: 100%;
    border-color: transparent;
}


    .CSV_Data {
        max-height: 70vh; /* You can adjust this value based on your UI design */
        max-width: 80%; /* You can adjust this value based on your UI design */
        overflow-y: auto; /* Enable vertical scrolling */
        overflow-x: auto; /* Enable horizontal scrolling for wider tables */
        border: 1px solid #ccc; /* Optional, just to make the div visually distinct */
        padding: 10px; /* Optional, for some internal spacing */
        margin-top: 10px; /* Optional, for spacing from the file input */
        background-color: white;

    }

    table {
        border-collapse: collapse; /* Ensures that borders between cells are not doubled */
        width: 100%; /* Optional, makes the table width fill the container */
    }
    
    th, td {
        border: 1px solid #ccc; /* Sets a light gray border for each cell */
        padding: 8px; /* Adds some space inside each cell */
        text-align: left; /* Aligns text to the left, adjust as needed */
    }
    

.menu_items {
    height:75%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40%;
}

.menu_title {
    margin-top: 10%;
    font-size: 3.2vmin;
    text-decoration: underline;
    display: flex;
    align-items: center;
    justify-content: center;
}

.import_data {
    display: flex;
    flex-direction: column;
}

.text_above_box {
    display: flex;
    align-items: flex-start;
    width:90%;
}

.create_button {
    height: 7vh;
    width: 35%;
    margin-top: 1%;
    border-radius: 25px;
    border-style: solid;
}
.create_button_csv {
    height: 7vh;
    width: 35%;
    margin-top: 5%;
    border-radius: 25px;
    border-style: solid;
    margin-left: 20%;
}

div.css-13cymwt-control {
    height: 6vh;
    width: 40vw;
    border-radius: 15px;
    margin-bottom: 5%;
}

div.css-b62m3t-container{
    height: 6vh;
    width: 35vw;
    border-radius: 15px;
    margin-bottom: 5%;
}
.Management_container {
    display: flex;
    flex-direction: column;
    width: auto;/*70%;*/
    align-items: left;
    margin-top: 7%;
    margin-left: 3vw;
}

.Management_msg {
    margin-bottom: 2%;
    font-size: x-large;
    font-weight: bold;
}

.import_data {
    margin-left: 10vw;
    margin-top: 5vh;
}

.sample_file {
    margin-bottom: 2vh;
}

.table_container {
    max-height: 20vh; /* Adjust the height as needed */
    overflow-y: auto;
    overflow-x: auto;
    margin-left: 12vw;
    max-width: 50vw;
}

.table_container table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
}

.table_container th, .table_container td {
    border: 1px solid #ddd;
    padding: 8px;
}

.table_container th {
    background-color: #f2f2f2;
    text-align: left;
}

input.form-control {
    margin-left: 12vw;
}

.rowC{display:flex; flex-direction:row;}
