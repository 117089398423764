.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.header {
    height: 10vh;
    width: 100vw;
    background-color: #D9D9D9;
    margin-top: 1vh;
    border-radius: 25px;
    font-weight: bolder;
    font-size: xx-large;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.app_title {
    margin-left: 0.5vw;
}

.logo {
    height: 8vh;
    width: auto;
    margin-left: 5vw;
}

.username {
    height: 5vh;
    width: 10vw;
    margin-left: 60vw;
    border-radius: 25px;
    border-color: black;
    border-width: 0.2vh;
    border-style: solid;
    font-size: x-large;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Login_Form {
    display: flex;
    height: 80%;
    width: 70%;
    margin-top: 5vh;
    border-radius: 25px;
    border-color: black;
    border-width: 0.2vh;
    border-style: solid;
    font-size: x-large;
    background-color: #b2d3b4;

}

.login_img {
    height: 100%;
    width: 45%;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    background-image: url("../Assets/viscat_logo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: whitesmoke;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70%;
    width: 40%;
    border-radius: 25px;
    border-color: black;
    border-width: 0.2vh;
    border-style: solid;
    margin-top: 10vh;
    margin-left: 5vw;
    background-color: whitesmoke;
}

.data_entry {
    display: flex;
    flex-direction: column;
    font-size: large;
}

.forgot_password {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    font-size: 1rem;
    width: 100%;
}

.forgot_password:hover {
    cursor: pointer;
}

.divider {
    background-color: black;
    height: 0.5vh;
    width: 80%;
    margin: 2vh;
}
.login {
    font-weight: bolder;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-decoration: underline;
    margin-top: -1.5vh
}

.entry_box {
    margin-bottom: 0.5vw;
    height: 4vh;
    width: 15vw;
    border-width: 0.2vh;
    border-style: solid;
    border-radius: 0.8vh;
    border-color: black;
}


.sign_up {
    cursor: pointer;
    font-weight: bold;
    color: #b2d3b4;
    
}

.sign_up:hover {
    text-decoration: underline;
}
.signup_text {
    font-size: 2vmin;
}

.login_button {
    height: 4vh;
    width: 8vw;
    margin-bottom: 2vh;
    margin-top: 2vh;
    cursor: pointer;
    border-radius: 20px;
    border-style: solid;
    background-color: #60A9C0;
    font-size: medium;
    border-color: black;
}

.SignUp_button {
        height: 4vh;
        width: 8vw;
        margin-bottom: 2vh;
        cursor: pointer;
        border-radius: 20px;
        border-style: solid;
        background-color: #60A9C0;
        font-size: medium;
        border-color: black;   
}

.forgotPasswordText:hover {
    text-decoration: underline;
}